export const seeds = {
  2023: {
    South: {
      Alabama: 1,
      Arizona: 2,
      Baylor: 3,
      Virginia: 4,
      "San Diego St.": 5,
      Creighton: 6,
      Missouri: 7,
      Maryland: 8,
      "West Virginia": 9,
      "Utah St.": 10,
      "NC State": 11,
      Charleston: 12,
      Furman: 13,
      UCSB: 14,
      Princeton: 15,
      "Texas A&M-CC": 16,
    },
    East: {
      Purdue: 1,
      Marquette: 2,
      "Kansas St.": 3,
      Tennessee: 4,
      Duke: 5,
      Kentucky: 6,
      "Michigan St.": 7,
      Memphis: 8,
      "Florida Atlantic": 9,
      USC: 10,
      Providence: 11,
      "Oral Roberts": 12,
      Louisiana: 13,
      "Montana St.": 14,
      Vermont: 15,
      "Fairleigh Dickinson": 16,
    },
    Midwest: {
      Houston: 1,
      Texas: 2,
      Xavier: 3,
      Indiana: 4,
      "Miami (FL)": 5,
      "Iowa St.": 6,
      "Texas A&M": 7,
      Iowa: 8,
      Auburn: 9,
      "Penn St.": 10,
      Pittsburgh: 11,
      Drake: 12,
      "Kent St.": 13,
      "Kennesaw St.": 14,
      Colgate: 15,
      "Northern Ky.": 16,
    },
    West: {
      Kansas: 1,
      UCLA: 2,
      Gonzaga: 3,
      UConn: 4,
      "Saint Mary's": 5,
      TCU: 6,
      Northwestern: 7,
      Arkansas: 8,
      Illinois: 9,
      "Boise St.": 10,
      "Arizona St.": 11,
      VCU: 12,
      Iona: 13,
      "Grand Canyon": 14,
      "UNC Asheville": 15,
      Howard: 16,
    },
    Nationals: {
      "San Diego St.": 5,
      "Florida Atlantic": 9,
      "Miami (FL)": 5,
      UConn: 4,
    },
  },
  2025: {
    South: {
      Auburn: 1,
      "Michigan St.": 2,
      "Iowa St.": 3,
      "Texas A&M": 4,
      Michigan: 5,
      "Ole Miss": 6,
      Marquette: 7,
      Louisville: 8,
      Creighton: 9,
      "New Mexico": 10,
      "North Carolina": 11,
      "UC San Diego": 12,
      Yale: 13,
      Lipscomb: 14,
      Bryant: 15,
      "Alabama St.": 16,
    },
    East: {
      Duke: 1,
      Alabama: 2,
      Wisconsin: 3,
      Arizona: 4,
      Oregon: 5,
      BYU: 6,
      "Saint Mary's": 7,
      "Mississippi St.": 8,
      Baylor: 9,
      Vanderbilt: 10,
      VCU: 11,
      Liberty: 12,
      Akron: 13,
      Montana: 14,
      "Robert Morris": 15,
      "Mount St. Mary's": 16,
    },
    Midwest: {
      Houston: 1,
      Tennessee: 2,
      Kentucky: 3,
      Purdue: 4,
      Clemson: 5,
      Illinois: 6,
      UCLA: 7,
      Gonzaga: 8,
      Georgia: 9,
      "Utah St.": 10,
      Xavier: 11,
      "McNeese St.": 12,
      "High Point": 13,
      Troy: 14,
      Wofford: 15,
      SIUE: 16,
    },
    West: {
      Florida: 1,
      "St. John's": 2,
      "Texas Tech": 3,
      Maryland: 4,
      Memphis: 5,
      Missouri: 6,
      Kansas: 7,
      UConn: 8,
      Oklahoma: 9,
      Arkansas: 10,
      Drake: 11,
      "Colorado St.": 12,
      "Grand Canyon": 13,
      "UNC Wilmington": 14,
      Omaha: 15,
      "Norfolk St.": 16,
    },
    Nationals: {
      Auburn: 1,
      Duke: 1,
      Houston: 1,
      Florida: 1,
    },
  },
};

export const rounds = {
  2023: {
    South: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Alabama" }, { name: "Texas A&M-CC" }],
          },
          {
            teams: [{ name: "Maryland" }, { name: "West Virginia" }],
          },
          {
            teams: [{ name: "San Diego St." }, { name: "Charleston" }],
          },
          {
            teams: [{ name: "Virginia" }, { name: "Furman" }],
          },
          {
            teams: [{ name: "Creighton" }, { name: "NC State" }],
          },
          {
            teams: [{ name: "Baylor" }, { name: "UCSB" }],
          },
          {
            teams: [{ name: "Missouri" }, { name: "Utah St." }],
          },
          {
            teams: [{ name: "Arizona" }, { name: "Princeton" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [
              { name: "Alabama" },
              { name: "Maryland", prediction: false },
            ],
          },
          {
            teams: [
              { name: "San Diego St." },
              { name: "Furman", prediction: false },
            ],
          },
          {
            teams: [{ name: "Creighton" }, { name: "Baylor" }],
          },
          {
            teams: [
              { name: "Missouri" },
              { name: "Princeton", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [
              { name: "Alabama", prediction: false },
              { name: "San Diego St." },
            ],
          },
          {
            teams: [
              { name: "Creighton" },
              { name: "Princeton", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [{ name: "San Diego St." }, { name: "Creighton" }],
          },
        ],
      },
    ],
    East: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Purdue" }, { name: "Fairleigh Dickinson" }],
          },
          {
            teams: [{ name: "Memphis" }, { name: "Florida Atlantic" }],
          },
          {
            teams: [{ name: "Duke" }, { name: "Oral Roberts" }],
          },
          {
            teams: [{ name: "Tennessee" }, { name: "Louisiana" }],
          },
          {
            teams: [{ name: "Kentucky" }, { name: "Providence" }],
          },
          {
            teams: [{ name: "Kansas St." }, { name: "Montana St." }],
          },
          {
            teams: [{ name: "Michigan St." }, { name: "USC" }],
          },
          {
            teams: [{ name: "Marquette" }, { name: "Vermont" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [
              { name: "Fairleigh Dickinson", prediction: false },
              { name: "Florida Atlantic" },
            ],
          },
          {
            teams: [{ name: "Duke" }, { name: "Tennessee" }],
          },
          {
            teams: [{ name: "Kentucky" }, { name: "Kansas St." }],
          },
          {
            teams: [{ name: "Michigan St." }, { name: "Marquette" }],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [
              { name: "Florida Atlantic", prediction: false },
              { name: "Tennessee" },
            ],
          },
          {
            teams: [
              { name: "Kansas St.", prediction: false },
              { name: "Michigan St.", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [
              { name: "Florida Atlantic", prediction: false },
              { name: "Kansas St.", prediction: false },
            ],
          },
        ],
      },
    ],
    Midwest: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Houston" }, { name: "Northern Ky." }],
          },
          {
            teams: [{ name: "Iowa" }, { name: "Auburn" }],
          },
          {
            teams: [{ name: "Miami (FL)" }, { name: "Drake" }],
          },
          {
            teams: [{ name: "Indiana" }, { name: "Kent St." }],
          },
          {
            teams: [
              { name: "Iowa St." },
              { name: "Pittsburgh", prediction: false },
            ],
          },
          {
            teams: [{ name: "Xavier" }, { name: "Kennesaw St." }],
          },
          {
            teams: [{ name: "Texas A&M" }, { name: "Penn St." }],
          },
          {
            teams: [{ name: "Texas" }, { name: "Colgate" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [{ name: "Houston" }, { name: "Auburn" }],
          },
          {
            teams: [{ name: "Miami (FL)" }, { name: "Indiana" }],
          },
          {
            teams: [
              { name: "Pittsburgh", prediction: false },
              { name: "Xavier" },
            ],
          },
          {
            teams: [{ name: "Penn St.", prediction: false }, { name: "Texas" }],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [
              { name: "Houston" },
              { name: "Miami (FL)", prediction: false },
            ],
          },
          {
            teams: [{ name: "Xavier", prediction: false }, { name: "Texas" }],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [
              { name: "Miami (FL)", prediction: false },
              { name: "Texas" },
            ],
          },
        ],
      },
    ],
    West: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Kansas" }, { name: "Howard" }],
          },
          {
            teams: [{ name: "Arkansas" }, { name: "Illinois" }],
          },
          {
            teams: [{ name: "Saint Mary's" }, { name: "VCU" }],
          },
          {
            teams: [{ name: "UConn" }, { name: "Iona" }],
          },
          {
            teams: [
              { name: "TCU" },
              { name: "Arizona St.", prediction: false },
            ],
          },
          {
            teams: [{ name: "Gonzaga" }, { name: "Grand Canyon" }],
          },
          {
            teams: [{ name: "Northwestern" }, { name: "Boise St." }],
          },
          {
            teams: [{ name: "UCLA" }, { name: "UNC Asheville" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [{ name: "Kansas" }, { name: "Arkansas" }],
          },
          {
            teams: [{ name: "Saint Mary's" }, { name: "UConn" }],
          },
          {
            teams: [{ name: "TCU" }, { name: "Gonzaga" }],
          },
          {
            teams: [{ name: "Northwestern" }, { name: "UCLA" }],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [{ name: "Arkansas", prediction: false }, { name: "UConn" }],
          },
          {
            teams: [{ name: "Gonzaga" }, { name: "UCLA" }],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [
              { name: "UConn", prediction: false },
              { name: "Gonzaga", prediction: false },
            ],
          },
        ],
      },
    ],
    Nationals: [
      {
        title: "Semifinals",
        seeds: [
          {
            teams: [
              { name: "San Diego St.", prediction: false },
              { name: "Florida Atlantic", prediction: false },
            ],
          },
          {
            teams: [
              { name: "Miami (FL)", prediction: false },
              { name: "UConn", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Championship",
        seeds: [
          {
            teams: [
              { name: "San Diego St.", prediction: false },
              { name: "UConn", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Winner",
        seeds: [
          {
            teams: [{ name: "UConn", prediction: false }],
          },
        ],
      },
    ],
  },
  2025: {
    South: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Auburn" }, { name: "Alabama St." }],
          },
          {
            teams: [{ name: "Louisville" }, { name: "Creighton" }],
          },
          {
            teams: [{ name: "Michigan" }, { name: "UC San Diego" }],
          },
          {
            teams: [{ name: "Texas A&M" }, { name: "Yale" }],
          },
          {
            teams: [{ name: "Ole Miss" }, { name: "North Carolina" }],
          },
          {
            teams: [{ name: "Iowa St." }, { name: "Lipscomb" }],
          },
          {
            teams: [{ name: "Marquette" }, { name: "New Mexico" }],
          },
          {
            teams: [{ name: "Michigan St." }, { name: "Bryant" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [
              { name: "Auburn" },
              { name: "Creighton", prediction: false },
            ],
          },
          {
            teams: [{ name: "Michigan" }, { name: "Texas A&M" }],
          },
          {
            teams: [{ name: "Ole Miss" }, { name: "Iowa St." }],
          },
          {
            teams: [
              { name: "New Mexico", prediction: false },
              { name: "Michigan St." },
            ],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [
              { name: "Auburn" },
              { name: "Michigan", prediction: false },
            ],
          },
          {
            teams: [
              { name: "Ole Miss", prediction: false },
              { name: "Michigan St." },
            ],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [{ name: "Auburn" }, { name: "Michigan St." }],
          },
        ],
      },
    ],
    East: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Duke" }, { name: "Mount St. Mary's" }],
          },
          {
            teams: [{ name: "Mississippi St." }, { name: "Baylor" }],
          },
          {
            teams: [{ name: "Oregon" }, { name: "Liberty" }],
          },
          {
            teams: [{ name: "Arizona" }, { name: "Akron" }],
          },
          {
            teams: [{ name: "BYU" }, { name: "VCU" }],
          },
          {
            teams: [{ name: "Wisconsin" }, { name: "Montana" }],
          },
          {
            teams: [{ name: "Saint Mary's" }, { name: "Vanderbilt" }],
          },
          {
            teams: [{ name: "Alabama" }, { name: "Robert Morris" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [{ name: "Duke" }, { name: "Baylor", prediction: false }],
          },
          {
            teams: [{ name: "Oregon" }, { name: "Arizona" }],
          },
          {
            teams: [{ name: "BYU" }, { name: "Wisconsin" }],
          },
          {
            teams: [{ name: "Saint Mary's" }, { name: "Alabama" }],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [{ name: "Duke" }, { name: "Arizona" }],
          },
          {
            teams: [{ name: "BYU", prediction: false }, { name: "Alabama" }],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [{ name: "Duke" }, { name: "Alabama" }],
          },
        ],
      },
    ],
    Midwest: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Houston" }, { name: "SIUE" }],
          },
          {
            teams: [{ name: "Gonzaga" }, { name: "Georgia" }],
          },
          {
            teams: [{ name: "Clemson" }, { name: "McNeese St." }],
          },
          {
            teams: [{ name: "Purdue" }, { name: "High Point" }],
          },
          {
            teams: [{ name: "Illinois" }, { name: "Xavier" }],
          },
          {
            teams: [{ name: "Kentucky" }, { name: "Troy" }],
          },
          {
            teams: [{ name: "UCLA" }, { name: "Utah St." }],
          },
          {
            teams: [{ name: "Tennessee" }, { name: "Wofford" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [{ name: "Houston" }, { name: "Gonzaga" }],
          },
          {
            teams: [
              { name: "McNeese St.", prediction: false },
              { name: "Purdue" },
            ],
          },
          {
            teams: [{ name: "Illinois" }, { name: "Kentucky" }],
          },
          {
            teams: [{ name: "UCLA" }, { name: "Tennessee" }],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [{ name: "Houston", prediction: false }, { name: "Purdue" }],
          },
          {
            teams: [{ name: "Kentucky" }, { name: "Tennessee" }],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [
              { name: "Houston", prediction: false },
              { name: "Tennessee" },
            ],
          },
        ],
      },
    ],
    West: [
      {
        title: "First Round",
        seeds: [
          {
            teams: [{ name: "Florida" }, { name: "Norfolk St." }],
          },
          {
            teams: [{ name: "UConn" }, { name: "Oklahoma" }],
          },
          {
            teams: [{ name: "Memphis" }, { name: "Colorado St." }],
          },
          {
            teams: [{ name: "Maryland" }, { name: "Grand Canyon" }],
          },
          {
            teams: [{ name: "Missouri" }, { name: "Drake" }],
          },
          {
            teams: [{ name: "Texas Tech" }, { name: "UNC Wilmington" }],
          },
          {
            teams: [{ name: "Kansas" }, { name: "Arkansas" }],
          },
          {
            teams: [{ name: "St. John's" }, { name: "Omaha" }],
          },
        ],
      },
      {
        title: "Second Round",
        seeds: [
          {
            teams: [{ name: "Florida" }, { name: "UConn" }],
          },
          {
            teams: [
              { name: "Colorado St.", prediction: false },
              { name: "Maryland" },
            ],
          },
          {
            teams: [
              { name: "Drake", prediction: false },
              { name: "Texas Tech" },
            ],
          },
          {
            teams: [
              { name: "Arkansas", prediction: false },
              { name: "St. John's" },
            ],
          },
        ],
      },
      {
        title: "Sweet 16",
        seeds: [
          {
            teams: [{ name: "Florida" }, { name: "Maryland" }],
          },
          {
            teams: [
              { name: "Texas Tech" },
              { name: "Arkansas", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Elite 8",
        seeds: [
          {
            teams: [
              { name: "Florida" },
              { name: "Texas Tech", prediction: false },
            ],
          },
        ],
      },
    ],
    Nationals: [
      {
        title: "Semifinals",
        seeds: [
          {
            teams: [{ name: "Auburn" }, { name: "Florida", prediction: false }],
          },
          {
            teams: [{ name: "Duke" }, { name: "Houston", prediction: false }],
          },
        ],
      },
      {
        title: "Championship",
        seeds: [
          {
            teams: [
              { name: "Florida", prediction: false },
              { name: "Houston", prediction: false },
            ],
          },
        ],
      },
      {
        title: "Winner",
        seeds: [
          {
            teams: [{ name: "TBD" }],
          },
        ],
      },
    ],
  },
};
